/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';
import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import cx from 'classnames';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { IconButton } from '@mui/material';
import Radio from '../CustomRadio';
import { ACTION_TYPES } from '../../enum/action';

import './styles.scss';

const FormGroup = ({
  children,
  label,
  center = false,
  full = false,
  self = false,
  required = false,
  horizontal = false,
  ...rest
}) =>
  horizontal ? (
    <Row
      className={cx({
        form__group: true,
        'horizontal flex-column-container': horizontal,
        full,
        self,
        [rest.extraClass]: rest.extraClass,
      })}
    >
      {label && (
        <Col sm={3} xs={12}>
          <p className="form__label">
            {label}
            {required && <span className="asterisk">*</span>}
          </p>
        </Col>
      )}
      <Col sm={label ? 9 : 12} xs={12}>
        <div className="form__content">{children}</div>
      </Col>
    </Row>
  ) : (
    <div
      className={cx({
        form__group: true,
        '-center': center,
        full,
        self,
        [rest.extraClass]: rest.extraClass,
      })}
    >
      <p className="form__label">
        {label}
        {required && <span className="asterisk">*</span>}
      </p>
      {children}
    </div>
  );

FormGroup.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string,
  center: PropTypes.bool,
  full: PropTypes.bool,
  self: PropTypes.bool,
  required: PropTypes.bool,
  horizontal: PropTypes.bool,
};

const TextInput = ({ value, type = 'text', placeholder, onChange, extraClass, multiline, ...rest }) => {
  const [show, toggleShow] = useState(false);
  const inputProps = {
    type,
    value,
    placeholder,
    onChange: (e) => onChange(e.target.value),
    className: `form__input form__textinput ${multiline ? 'multiline' : ''} ${extraClass || ''}`,
    ...rest,
  };
  if (type === 'password') {
    return (
      <div className="password-input">
        <input {...inputProps} type={show ? 'text' : 'password'} />
        <IconButton size="small" onClick={() => toggleShow(!show)}>
          {show ? <VisibilityOffOutlinedIcon title="Hide" /> : <VisibilityOutlinedIcon title="Show" />}
        </IconButton>
      </div>
    );
  }
  if (multiline) {
    return <textarea {...inputProps} />;
  }
  return <input {...inputProps} />;
};

TextInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  extraClass: PropTypes.string,
};

const RadioButton = ({ selected, onChange, value, label, extraClass = '', disabled = false, ...rest }) => (
  <div className={`form__input form__radioButton ${extraClass || ''} ${disabled ? 'disabled' : ''}`}>
    <Radio
      onChange={(e) => onChange(e.target.value)}
      checked={selected === value}
      label={label}
      value={value}
      {...rest}
    />
  </div>
);

RadioButton.propTypes = {
  value: PropTypes.any,
  selected: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  extraClass: PropTypes.string,
  disabled: PropTypes.bool,
};

const Checkbox = ({ onChange, label, checked, extraClass = '', disabled = false, ...rest }) => (
  <label className={`checkbox-label wedge-checkbox-container ${extraClass || ''} ${disabled ? 'disabled' : ''}`}>
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={(ev) => onChange(ev.target.checked)}
      {...rest}
    />
    <span className="checkmark" />
    <p className="check_title">{label}</p>
  </label>
);
Checkbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  extraClass: PropTypes.string,
};

const ToggleButton = ({ selectedClass, selected, onChange, options, extraClass = '', ...props }) => (
  <ButtonGroup className={`space-bottom ${extraClass || ''}`} {...props}>
    {options.map((opt) => (
      <Button
        key={`toggle-button-index-key-${opt.value}-${opt.name}`}
        onClick={() => onChange(opt.value)}
        disabled={opt.disabled}
        className={`form__input form__toggle ${
          selected === opt.value ? (selected === ACTION_TYPES.DROP || !selected ? 'select_deny' : selectedClass) : ''
        } ${extraClass}`}
        bsStyle={selected === opt.value ? 'primary' : 'default'}
      >
        {opt.label}
      </Button>
    ))}
  </ButtonGroup>
);

ToggleButton.propTypes = {
  selectedClass: PropTypes.string,
  selected: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  extraClass: PropTypes.string,
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    paddingLeft: 0,
    boxShadow: 'none',
    '&:hover': { borderColor: '#f68b1e' },
    border: '1px solid transparent',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: 0,
  }),
  menuContainerStyle: (styles) => ({
    ...styles,
    zIndex: 50005,
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 101,
    minWidth: 250,
  }),
};

const SelectInput = ({ placeholder, value, onChange, options }) => (
  <Select
    className="form__input form__select"
    classNamePrefix="react-select"
    name="color"
    maxMenuHeight="250"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    options={options}
    styles={selectStyles}
  />
);

SelectInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default {
  Group: memo(FormGroup),
  Text: memo(TextInput),
  Select: memo(SelectInput),
  Toggle: memo(ToggleButton),
  Checkbox: memo(Checkbox),
  RadioButton: memo(RadioButton),
};
