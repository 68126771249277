import _ from 'lodash';
import * as REST from '../../api/rest';

import Notification from '../../components/Notification';
import { OBJECT_MENU_CONFIG, OBJECT_TYPES } from '../../enum/object';
import {
  creationObjectFailed,
  creationObjectStarted,
  creationObjectSuccess,
  fetchingObjectsFailed,
  fetchingObjectsStarted,
  fetchingObjectsSuccess,
  updateObjectFailed,
  updateObjectStarted,
  updateObjectSuccess,
  deleteObjectFailed,
  deleteObjectStarted,
  deleteObjectSuccess,
  generateTokenStarted,
  generateTokenSuccess,
  generateTokenFailed,
  createPolicyObject,
  regenPSKFailed,
  regenPSKStarted,
  regenPSKSuccess,
  readGatewayStarted,
  readGatewaySuccess,
  readGatewayFailed,
  generateImageStarted,
  generateImageSuccess,
  generateImageFailed,
  userInvitationStarted,
  userInvitationSuccess,
  userInvitationFailed,
  updateLDAPPasswordStarted,
  updateLDAPPasswordSuccess,
  updateLDAPPasswordFailure,
  updateLdapStatusStarted,
  updateLdapStatusSuccess,
  updateLdapStatusFailed,
  changeUserStatusStarted,
  changeUserStatusSuccess,
  changeUserStatusFailed,
  resetMFAStarted,
  resetMFASuccess,
  resetMFAFailed,
} from '../../store/objects/actions';
import {
  getEcoUserStarted,
  getEcoUserSuccess,
  getEcoUserFailed,
  createEcosystemGroupSuccess,
} from '../../store/ecosystems/actions';
import populator from '../../utils/populator';

export function fetchObjects(ecosystemUUID) {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem;
      const { groups } = getState().ecosystems.dictionaries;
      dispatch(fetchingObjectsStarted());
      const things = await REST.fetchObjects({
        customer: getState().customers.currentCustomer,
        ecosystem: ecosystemUUID,
        type: OBJECT_TYPES.THING,
      });
      const scopes = [
        'default',
        'public',
        'any-public',
        'external-public',
        'private',
        'any-private',
        'external-private',
      ];
      const [addresses, gateways, servers] = await Promise.all([
        REST.fetchObjects({
          customer: getState().customers.currentCustomer,
          ecosystem: ecosystemUUID,
          type: OBJECT_TYPES.ADDRESS,
          payload: {
            params: { scope: scopes.join(',') },
          },
        }),
        REST.fetchObjects({
          customer: getState().customers.currentCustomer,
          ecosystem: ecosystemUUID,
          type: OBJECT_TYPES.GATEWAY,
        }),
        REST.fetchObjects({
          customer: getState().customers.currentCustomer,
          ecosystem: ecosystemUUID,
          type: OBJECT_TYPES.SERVER,
        }),
      ]);

      const sortedServers = _.sortBy(servers, 'priority');

      const users = [];

      let remapedObjects = things.map((ob) => ({
        ...ob,
        profileGroup: groups.find((gr) => gr.uuid === ob.profileGroup),
      }));

      remapedObjects = remapedObjects.concat(addresses, gateways, users, sortedServers);
      dispatch(fetchingObjectsSuccess(remapedObjects, ecosystem));
    } catch (err) {
      dispatch(fetchingObjectsFailed(err));
    }
  };
}

export function createObject(entity, type, field = '') {
  return async (dispatch, getState) => {
    try {
      const customer = getState().customers.currentCustomer;
      const ecosystem = getState().ecosystems.currentEcosystem;
      const { groups } = getState().ecosystems.dictionaries;

      dispatch(creationObjectStarted());
      if (type === OBJECT_TYPES.GROUPS) {
        const group = await REST.createGroup({
          customerUUID: customer.uuid,
          ecosystemUUID: ecosystem.uuid,
          name: entity.name,
        });
        dispatch(createEcosystemGroupSuccess([...groups, group]));
      } else {
        const objects = await REST.createObject({
          object: populator[type](entity),
          type,
          ecosystem: ecosystem.uuid,
          customer,
        }).then((object) => ({
          ...object,
          profileGroup: groups.find((gr) => gr.uuid === object.profileGroup),
        }));
        dispatch(creationObjectSuccess(objects, ecosystem));

        if (type === OBJECT_TYPES.SERVER) {
          const params = { customerUUID: customer.uuid, ecosystemUUID: ecosystem.uuid };
          const groups = await REST.fetchGroups(params);
          dispatch(createEcosystemGroupSuccess(groups));
        }

        if (field) {
          dispatch(createPolicyObject({ object: objects, field }));
        }
      }

      const objectLabel = OBJECT_MENU_CONFIG.find((option) => option.type === type).label;
      Notification({ message: `New ${objectLabel} has been created` });
    } catch (error) {
      dispatch(creationObjectFailed(error));
      throw error;
    }
  };
}

export function updateObject(entity, type, uuid) {
  return async (dispatch, getState) => {
    try {
      const customer = getState().customers.currentCustomer;
      const ecosystem = getState().ecosystems.currentEcosystem;
      const { groups } = getState().ecosystems.dictionaries;
      dispatch(updateObjectStarted());
      if (type === OBJECT_TYPES.GROUPS) {
        const group = await REST.updateGroup({ customer, ecosystem, uuid, ...entity });
        const index = groups.findIndex(({ uuid }) => group.uuid === uuid);
        groups[index] = { ...group };
        dispatch(createEcosystemGroupSuccess([...groups]));
      } else {
        const obj = populator[type](entity);
        if (type === OBJECT_TYPES.ADDRESS) {
          obj.uuid = uuid;
        }
        const object = await REST.updateObject({
          customer,
          ecosystem: ecosystem.uuid,
          object: obj,
          type,
          uuid,
        }).then((object) => ({
          ...object,
          profileGroup: groups.find((gr) => gr.uuid === object.profileGroup),
        }));
        dispatch(updateObjectSuccess(object, ecosystem));
      }

      const objectLabel = OBJECT_MENU_CONFIG.find((option) => option.type === type).label;
      if (type === OBJECT_TYPES.GATEWAY) {
        localStorage.removeItem(uuid);
      }
      Notification({
        message: `${entity.name} (${objectLabel}) has been updated`,
      });
    } catch (error) {
      dispatch(updateObjectFailed(error));
      throw error;
    }
  };
}

export function deleteObject(entity) {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem;
      const customer = getState().customers.currentCustomer;
      dispatch(deleteObjectStarted());
      if (entity.type === OBJECT_TYPES.GROUPS) {
        const groups = [...getState().ecosystems.dictionaries.groups];
        await REST.deleteGroup({ customer, ecosystem, uuid: entity.uuid });
        const index = groups.findIndex(({ uuid }) => entity.uuid === uuid);
        groups.splice(index, 1);
        dispatch(createEcosystemGroupSuccess([...groups]));
      } else {
        await REST.deleteObject({
          customer,
          ecosystem: ecosystem.uuid,
          object: entity,
        });
      }
      dispatch(deleteObjectSuccess());

      const objType =
        entity.element === OBJECT_TYPES.ADDRESS && entity.scope === 'public'
          ? OBJECT_TYPES.PUBLIC_ADDRESS
          : entity.element;
      const objectLabel = OBJECT_MENU_CONFIG.find((option) => option.type === objType).label;
      if (entity.element === 'gateway') {
        localStorage.removeItem(entity.uuid);
      }
      Notification({
        message: `${entity.name} (${objectLabel}) has been removed`,
      });
    } catch (err) {
      dispatch(deleteObjectFailed(err));
      throw err;
    }
  };
}

export function readGateway(gateway) {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem;
      const customer = getState().customers.currentCustomer;
      dispatch(readGatewayStarted());
      const result = await REST.readGateway({
        customer: customer.uuid,
        ecosystem: ecosystem.uuid,
        gateway: gateway.uuid,
      });
      result.element = 'gateway';
      const payload = { ecosystem, result };
      dispatch(readGatewaySuccess(payload));
    } catch (error) {
      dispatch(readGatewayFailed(error));
    }
  };
}

export function generateToken(gateway, option) {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem;
      const customer = getState().customers.currentCustomer;
      const platform = option.value;
      dispatch(generateTokenStarted());
      const { token } = await REST.getGatewayToken({
        customer: customer.uuid,
        ecosystem: ecosystem.uuid,
        gateway: gateway.uuid,
        platform,
      });
      const url = platform === 'strongswan' ? REST.getConfigLink(token, platform) : '';
      const storeGateway = localStorage.getItem(gateway.uuid)
        ? JSON.parse(localStorage.getItem(gateway.uuid))
        : { name: gateway.name };
      storeGateway[platform] = {
        token,
        url,
        createdAt: new Date(),
        updatedAt: new Date(),
        label: option.label,
      };
      localStorage.setItem(gateway.uuid, JSON.stringify(storeGateway));
      dispatch(generateImageStarted({ uuid: gateway.uuid, platform }));
      if (platform === 'strongswan') {
        dispatch(generateImageSuccess({ uuid: gateway.uuid, platform }));
        Notification({
          message: `${option.label} is ready to download from ${gateway.name}`,
        });
      }
      dispatch(generateTokenSuccess());
    } catch (err) {
      dispatch(generateTokenFailed(err));
    }
  };
}

export function generateImgTimeout(gateway, option) {
  return async (dispatch) => {
    const storeGateway = localStorage.getItem(gateway.uuid) ? JSON.parse(localStorage.getItem(gateway.uuid)) : {};
    if (storeGateway) {
      storeGateway[option.value] = {
        ...storeGateway[option.value],
        error: 'Time out. Please try again.',
      };
    }
    localStorage.setItem(gateway.uuid, JSON.stringify(storeGateway));
    dispatch(
      generateImageFailed({
        uuid: gateway.uuid,
        platform: option.value,
        message: '',
      }),
    );
  };
}

export function regeneratePSK(gatewayUUID) {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem;
      const customer = getState().customers.currentCustomer;
      dispatch(regenPSKStarted());
      const result = await REST.regeneratePSK({
        customer: customer.uuid,
        ecosystem: ecosystem.uuid,
        gateway: gatewayUUID,
      });
      dispatch(
        regenPSKSuccess({
          psk: result.psk,
          ecoUUID: ecosystem.uuid,
          gatewayUUID,
        }),
      );
    } catch (error) {
      dispatch(regenPSKFailed(error));
    }
  };
}

export function sendUserInvitations(payload) {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem;
      const customer = getState().customers.currentCustomer;
      dispatch(userInvitationStarted());
      const result = await REST.sendUserInvitations({
        customer: customer.uuid,
        ecosystem: ecosystem.uuid,
        payload,
      });
      dispatch(userInvitationSuccess({ result }));
    } catch (error) {
      dispatch(userInvitationFailed(error));
    }
  };
}

export function getEcosystemUsers() {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem.uuid;
      const customer = getState().customers.currentCustomer.uuid;
      dispatch(getEcoUserStarted());
      const users = await REST.getEcosystemUsers({ customer, ecosystem });
      dispatch(getEcoUserSuccess(users));
    } catch (error) {
      dispatch(getEcoUserFailed(error));
    }
  };
}

export function updateLDAPPassword(ldapUUID, data) {
  return async (dispatch, getState) => {
    const customerUUID = getState().customers.currentCustomer.uuid;
    const ecosystemUUID = getState().ecosystems.currentEcosystem.uuid;
    try {
      dispatch(updateLDAPPasswordStarted());
      const { uuid } = await REST.updateLDAPPassword({
        customerUUID,
        ecosystemUUID,
        ldapUUID,
        data,
      });
      dispatch(updateLDAPPasswordSuccess({ ecosystemUUID, uuid }));
      Notification({ message: `Credentials have been updated` });
    } catch (error) {
      dispatch(updateLDAPPasswordFailure(error));
    }
  };
}

export function updateLdapStatus(ldapUUID) {
  return async (dispatch, getState) => {
    try {
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystemUUID = getState().ecosystems.currentEcosystem.uuid;
      dispatch(updateLdapStatusStarted({ ecosystemUUID, uuid: ldapUUID }));
      const { status } = await REST.updateLdapStatus({
        customerUUID,
        ecosystemUUID,
        ldapUUID,
      });
      dispatch(updateLdapStatusSuccess({ ecosystemUUID, uuid: ldapUUID, status }));
    } catch (error) {
      dispatch(updateLdapStatusFailed(error));
    }
  };
}

export function changeUserStatus(payload) {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem;
      const customer = getState().customers.currentCustomer;
      dispatch(changeUserStatusStarted());
      const result = await REST.enableDisableMFAForUsers({
        customer: customer.uuid,
        ecosystem: ecosystem.uuid,
        payload,
      });
      dispatch(changeUserStatusSuccess({ result }));
    } catch (error) {
      dispatch(changeUserStatusFailed(error));
    }
  };
}
export function resetMFA(payload) {
  return async (dispatch, getState) => {
    try {
      const ecosystem = getState().ecosystems.currentEcosystem;
      const customer = getState().customers.currentCustomer;
      dispatch(resetMFAStarted());
      const result = await REST.resetMFAForUsers({
        customer: customer.uuid,
        ecosystem: ecosystem.uuid,
        payload,
      });
      dispatch(resetMFASuccess({ result }));
    } catch (error) {
      dispatch(resetMFAFailed(error));
    }
  };
}
