import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import HeaderLinks from '../Header/HeaderLinks';
import { OBJECT_MENU_CONFIG } from '../../enum/object';
import { ROUTES } from '../../enum';
import {
  ACRETO_LOGO,
  BACK_ARROW,
  CHEVRON_DOWN_WHITE,
  FAKE_CHART_SIDEBAR,
  FULL_SIZE_ICON,
  GREEN_TICK,
  MENU_ADDRESS,
  MENU_ELEMENTS,
  MENU_OBJECTS,
  MENU_SECURITY,
  MENU_POLICY,
  MENU_PROFILE,
  MENU_WEBFILTER,
  MENU_REPORT,
  NOTIFICATIONS_ARROW_RIGHT,
} from '../../assets/Icons';
import ThingIcon from '../../assets/img/PNG/device.png';
import SettingSVG from '../../assets/img/SVG/gear.svg';
import DesktopSVG from '../../assets/img/SVG/desktop.svg';
import './styles.scss';

function objRouteExists(url) {
  return !!url && ['objects', ...OBJECT_MENU_CONFIG.map((obj) => obj.url)].includes(url);
}

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      objOpened: true,
      profileOpened: false,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  static getDerivedStateFromProps(props) {
    const {
      location: { pathname },
    } = props;
    const splitURL = pathname.split('/');
    if (!objRouteExists(splitURL[6])) {
      const returnState = { objOpened: false };
      if (splitURL[6] !== 'security' || !splitURL[7] || !['web-filter', 'decryption'].includes(splitURL[7])) {
        returnState.profileOpened = false;
      }
      return returnState;
    }
    return null;
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  activeRoute = (routeName) => (this.props.location.pathname === routeName ? 'active' : '');

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const {
      ecosystem,
      customer,
      location: { pathname },
    } = this.props;
    const { objOpened, profileOpened } = this.state;
    const splittedPathname = pathname.split('/');
    const customersURL = splittedPathname.slice(0, 5).join('/');
    const ecosystemURL = splittedPathname.slice(0, 6).join('/');
    if (!ecosystem) {
      return <Redirect to={customersURL} />;
    }

    return (
      <div id="sidebar" className="sidebar wedge" data-color="gray">
        <div className="logo">
          <a href={ROUTES.CUSTOMERS} className="simple-text logo-normal full-width">
            <div className="logo-img">
              <img src={customer.logo || ACRETO_LOGO} alt="logo_image" />
            </div>
          </a>
          <div className="divider" />
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <HeaderLinks /> : null}
            <li>
              <NavLink to={customersURL} className="nav-link ecosystem">
                <img src={BACK_ARROW} alt="back-arrow" className="back-arrow" />
                <p className="ecosystem--text">All Ecosystems</p>
              </NavLink>
              <div className="sidebar-divider" />
            </li>
            <div className="ecosystem--name" title={ecosystem.name}>
              {ecosystem.name}
            </div>
            <li className={this.activeRoute(`${ecosystemURL}/guided-tasks`)}>
              <NavLink to={`${ecosystemURL}/guided-tasks`} className="nav-link root">
                <img className="small-image" alt="guided-tasks" src={MENU_REPORT} />
                <p>Guided Tasks</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${ecosystemURL}/objects`}
                className="nav-link root with-arrow"
                onClick={() => this.setState({ objOpened: false })}
              >
                <div className="left-container">
                  <img className="small-image" alt="Elements" src={MENU_ELEMENTS} />
                  <p>Elements</p>
                </div>
              </NavLink>
            </li>
            <li className={`${this.activeRoute(`${ecosystemURL}/objects`)} nested`}>
              <NavLink
                to={`${ecosystemURL}/objects`}
                className="nav-link"
                onClick={() => this.setState({ objOpened: true })}
              >
                <img className="small-image" alt="Objects" src={MENU_OBJECTS} />
                <p>Objects</p>
              </NavLink>
            </li>
            <div className="submenu">
              <Collapse in={objOpened}>
                <div>
                  {OBJECT_MENU_CONFIG.filter(({ objIdentity }) => !objIdentity).map((objType) => (
                    <NavLink
                      key={`submenu-${objType.url}`}
                      to={`${ecosystemURL}/objects/${objType.url}`}
                      className={`${this.activeRoute(`${ecosystemURL}/objects/${objType.url}`)} nav-link`}
                    >
                      <div className="obj-icon">
                        <img src={objType.icon} alt={`obj-icon-${objType.url}`} style={objType.iconStyle} />
                      </div>
                      <p>{objType.title}</p>
                    </NavLink>
                  ))}
                  <div className="identity">
                    <div className="obj-icon">
                      <img src={ThingIcon} alt="object-identity" style={{ width: 14 }} />
                    </div>
                    <p>Identity</p>
                  </div>
                  <div className="obj-identities">
                    {OBJECT_MENU_CONFIG.filter(({ objIdentity }) => !!objIdentity).map((objType) => (
                      <NavLink
                        key={`submenu-${objType.url}`}
                        to={`${ecosystemURL}/objects/${objType.url}`}
                        className={`${this.activeRoute(`${ecosystemURL}/objects/${objType.url}`)} nav-link`}
                      >
                        <div className="obj-icon">
                          <img src={objType.icon} alt={`obj-icon-${objType.url}`} style={objType.iconStyle} />
                        </div>
                        <p>{objType.title}</p>
                      </NavLink>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>
            <li className={this.activeRoute(`${ecosystemURL}/addresstranslations`)}>
              <NavLink to={`${ecosystemURL}/addresstranslations`} className="nav-link root">
                <img className="small-image translations" alt="Address Translation" src={MENU_ADDRESS} />
                <p>Address Translation</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${ecosystemURL}/security`} className="nav-link root">
                <img className="small-image security" alt="Policies" src={MENU_SECURITY} />
                <p>Security</p>
              </NavLink>
            </li>
            <li className={`${this.activeRoute(`${ecosystemURL}/security`)} nested`}>
              <NavLink to={`${ecosystemURL}/security`} className="nav-link" activeClassName="active">
                <img className="small-image policy" alt="Security" src={MENU_POLICY} />
                <p>Policies</p>
              </NavLink>
            </li>
            <li className="nested">
              <NavLink
                to={`${ecosystemURL}/security/web-filter`}
                className="nav-link"
                onClick={() => this.setState({ profileOpened: true })}
              >
                <img className="small-image profile" alt="Security Profile" src={MENU_PROFILE} />
                <p>Profiles</p>
              </NavLink>
            </li>
            <div className="submenu">
              <Collapse in={profileOpened}>
                <div>
                  <NavLink
                    to={`${ecosystemURL}/security/web-filter`}
                    className={`${this.activeRoute(`${ecosystemURL}/security/web-filter`)} nav-link`}
                  >
                    <img className="icon_webfilter" src={MENU_WEBFILTER} alt="Web Ftiler" />
                    <p>Website Filtering</p>
                  </NavLink>
                  <NavLink
                    to={`${ecosystemURL}/security/decryption`}
                    className={`${this.activeRoute(`${ecosystemURL}/security/decryption`)} nav-link`}
                  >
                    <img className="icon_webfilter" src={MENU_WEBFILTER} alt="Web Ftiler" />
                    <p>Decryption</p>
                  </NavLink>
                </div>
              </Collapse>
            </div>
            <li>
              <NavLink to={`${ecosystemURL}/logs`} className="nav-link root">
                <img className="small-image" alt="Logs" src={MENU_REPORT} />
                <p>Logs</p>
              </NavLink>
            </li>
            <li className={`${this.activeRoute(`${ecosystemURL}/logs`)} nested`}>
              <NavLink to={`${ecosystemURL}/logs`} className="nav-link" activeClassName="active">
                <img className="small-image" alt="Reports" src={MENU_REPORT} />
                <p>All</p>
              </NavLink>
            </li>
            <li className={`${this.activeRoute(`${ecosystemURL}/logs/traffic`)} nested`}>
              <NavLink to={`${ecosystemURL}/logs/traffic`} className="nav-link" activeClassName="active">
                <img className="small-image" alt="Reports" src={MENU_REPORT} />
                <p>Traffic</p>
              </NavLink>
            </li>
            <li className={`${this.activeRoute(`${ecosystemURL}/logs/gateway`)} nested`}>
              <NavLink to={`${ecosystemURL}/logs/gateway`} className="nav-link" activeClassName="active">
                <img className="small-image" alt="Reports" src={MENU_REPORT} />
                <p>Gateways</p>
              </NavLink>
            </li>
            <li className={`${this.activeRoute(`${ecosystemURL}/logs/usersandthings`)} nested`}>
              <NavLink to={`${ecosystemURL}/logs/usersandthings`} className="nav-link" activeClassName="active">
                <img className="small-image" alt="Reports" src={MENU_REPORT} />
                <p>Users and Things</p>
              </NavLink>
            </li>
            <li className={`${this.activeRoute(`${ecosystemURL}/logs/settings`)} nested`}>
              <NavLink to={`${ecosystemURL}/logs/settings`} className="nav-link" activeClassName="active">
                <img className="small-image" alt="Settings" src={MENU_REPORT} />
                <p>Settings</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${ecosystemURL}/settings/admin-access`} className="nav-link root">
                <img className="small-image" alt="Settings" src={SettingSVG} />
                <p>Ecosystem Settings</p>
              </NavLink>
            </li>
            <li className={`${this.activeRoute(`${ecosystemURL}/settings/admin-access`)} nested`}>
              <NavLink to={`${ecosystemURL}/settings/admin-access`} className="nav-link" activeClassName="active">
                <img className="small-image" alt="Settings" src={DesktopSVG} />
                <p>Admin Access</p>
              </NavLink>
            </li>
          </ul>
          <div className="sidebar-divider" />
          <div className="hide-component">
            <div className="sidebar-item threat-index component-coming-soon">
              <div className="flex-column">
                <div className="centered-row">
                  <p className="threat-index--value">40</p>
                  <img alt="threat-indicator" src={GREEN_TICK} className="threat-index--image" />
                </div>
                <p className="threat-index--title">Threat Index</p>
              </div>
              <img src={FAKE_CHART_SIDEBAR} className="threat-index--chart" alt="threat-chart" />
            </div>
            <div className="sidebar-divider" />
            <div className="sidebar-item status-info component-coming-soon">
              <div className="flex-column equal">
                <p className="status-info--good-value">15</p>
                <p className="status-info--title">Connected</p>
              </div>
              <div className="divider" />
              <div className="flex-column equal right">
                <p className="status-info--bad-value">345</p>
                <p className="status-info--title">Down</p>
              </div>
            </div>
            <div className="sidebar-divider" />
            <div className="sidebar-item notifications component-coming-soon">
              <div className="centered-row notifications--title-container">
                <p className="notifications--title">Status notifications</p>
                <img src={CHEVRON_DOWN_WHITE} className="notifications--chevron" alt="chevron-down" />
                <img src={FULL_SIZE_ICON} className="notifications--fullsize" alt="fullsize" />
              </div>
              {this.props.notifications.map((not) => (
                <div key={`notifications-index-${not.id}`} className="notifications--item">
                  <img src={NOTIFICATIONS_ARROW_RIGHT} className="notifications--arrow" alt="arrow" />
                  <p className="notifications--content">{not.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  ecosystem: {},
  notifications: [
    {
      id: 1,
      content: 'Sed ut perspiciatis unde omnis',
    },
    {
      id: 2,
      content: 'Sed ut perspiciatis unde omnis',
    },
  ],
};

Sidebar.propTypes = {
  location: PropTypes.object.isRequired,
  ecosystem: PropTypes.object,
  customer: PropTypes.object.isRequired,
  notifications: PropTypes.array,
};

const mapStateToProps = (state) => ({
  ecosystem: state.ecosystems.currentEcosystem,
  customer: state.customers.currentCustomer,
});

export default connect(mapStateToProps, null)(Sidebar);
