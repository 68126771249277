import axios from 'axios';
import qs from 'query-string';

import { CONSTANTS } from '../enum';
import Translator from '../utils/enumTranslator';
import configStore from '../store';
import { logoutUser, renewToken } from '../store/auth/actions';
import { loading } from '../store/global/actions';

const { store } = configStore;

export const rest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

rest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
    const { method, url } = config;

    if (token === null) {
      store.dispatch(logoutUser());
      return config;
    }
    if (['post', 'put', 'delete', 'patch'].findIndex((meth) => meth === method) !== -1) {
      if (!url.includes('/logs/_search')) {
        store.dispatch(loading(true));
      }
    }
    const newConfig = config;
    newConfig.headers.Authorization = `Bearer ${token}`;

    return newConfig;
  },
  (err) => Promise.reject(err),
);

rest.interceptors.response.use(
  (response) => {
    store.dispatch(renewToken());
    const {
      config: { method, url },
    } = response;
    if (['post', 'put', 'delete', 'patch'].findIndex((meth) => meth === method) !== -1) {
      if (!url.includes('/logs/_search')) {
        store.dispatch(loading(false));
      }
    }
    return response;
  },
  (error) => {
    if (['post', 'put', 'delete', 'patch'].findIndex((meth) => meth === error.config.method) !== -1) {
      store.dispatch(loading(false));
    }
    if (error.status === 401 || error.response.status === 401) {
      localStorage.removeItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
      store.dispatch(logoutUser());
    }
    return Promise.reject(error);
  },
);

export function checkAPIStatus() {
  return rest.get('/v2/status').then((response) => response.data);
}

export function getUser(email) {
  return rest.get(`/v2/users/${email}`).then((response) => response.data);
}

export const getCustomersList = () => rest.get('/v2/customers').then((response) => response.data);

export function fecthCustomerDetail({ customerUUID }) {
  return rest.get(`/v2/customers/${customerUUID}`).then((response) => response.data);
}

export function fetchEcosystems({ customerUUID }) {
  return rest.get(`/v2/customers/${customerUUID}/ecosystems`).then((response) => response.data);
}

export function fetchEcosystem({ customerUUID, ecosystemUUID }) {
  return rest.get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}`).then((response) => response.data);
}

export function createEcosystem({ customer, entity }) {
  return rest.post(`/v2/customers/${customer.uuid}/ecosystems`, entity).then((response) => response.data);
}

export function editEcosystem({ ecosystem, customer }) {
  return rest
    .post(`/v2/customers/${customer}/ecosystems/${ecosystem.uuid}`, ecosystem)
    .then((response) => response.data);
}

export function renameEcosystem({ ecosystem, customer }) {
  return rest
    .put(`/v2/customers/${customer}/ecosystems/${ecosystem.uuid}/rename`, ecosystem)
    .then((response) => response.data);
}

export function removeEcosystem({ customer, ecosystem }) {
  return rest.delete(`v2/customers/${customer}/ecosystems/${ecosystem}`).then((response) => response.data);
}

export function getEcosystemUsers({ customer, ecosystem }) {
  return rest.get(`v2/customers/${customer}/ecosystems/${ecosystem}/users`).then((response) => response.data);
}

export function grantAccess({ customer, ecosystem, user }) {
  return rest
    .post(`v2/customers/${customer}/ecosystems/${ecosystem}/access/${user.role}`, { username: user.email })
    .then((response) => response.data);
}

export function getAccessUser({ customer, ecosystem }) {
  return rest.get(`v2/customers/${customer}/ecosystems/${ecosystem}/access`).then((response) => response.data);
}

export function removeUserAcces({ customer, ecosystem, user }) {
  return rest
    .delete(`v2/customers/${customer}/ecosystems/${ecosystem}/access/${user.role}/${user.email}`)
    .then((response) => response.data);
}

export function upgradeEcosystem({ customer, ecosystem }) {
  return rest.post(`v2/customers/${customer}/ecosystems/${ecosystem}/upgrade`).then((response) => response.data);
}

export function recoverEcosystem({ customer, ecosystem }) {
  return rest.post(`v2/customers/${customer}/ecosystems/${ecosystem}/recover`).then((response) => response.data);
}

export function fetchObjects({ customer, ecosystem, type, payload = {} }) {
  return rest
    .get(getUrlForType({ type, ecosystem, customer }), payload)
    .then((response) => response.data)
    .then((data) => data.map((d) => ({ ...d, element: type })));
}

export function fetchLogSettings({ customer, ecosystem, payload = {} }) {
  return rest
    .get(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem}/logs/syslog`, payload)
    .then((response) => response.data);
}

export function createLogSettings({ object, type, ecosystem, customer }) {
  return rest
    .post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem}/logs/syslog`, object)
    .then((response) => response.data)
    .then((data) => ({ ...data, element: type }));
}

export function updateLogSettings({ object, ecosystem, customer }) {
  return rest
    .put(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem}/logs/syslog/${object.uuid}`, object)
    .then((response) => response.data);
}

export function deleteLogSettings({ customer, ecosystem, object }) {
  return rest
    .delete(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem}/logs/syslog/${object.uuid}`)
    .then((response) => response.data);
}

export function fetchAddresses({ customer, ecosystem }) {
  return rest
    .get(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/addresses`)
    .then((response) => response.data);
}

export function fetchPolicies({ customerUUID, ecosystemUUID }) {
  return rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/policies`)
    .then((response) => response.data);
}
export function fetchDNATs({ customer, ecosystem }) {
  return rest.get(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/nat`).then((response) => response.data);
}

export function createPolicy({ customerUUID, ecosystemUUID, payload }) {
  return rest
    .post(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/policies`, payload)
    .then((response) => response.data);
}
export function createDNAT({ customer, ecosystem, entity }) {
  return rest
    .post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/nat`, entity)
    .then((response) => response.data);
}

export const getUrlCategories = ({ customer, ecosystem }) =>
  rest
    .get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/categories`)
    .then((response) => response.data);

export const getUrlLists = ({ customer, ecosystem }) =>
  rest.get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/list`).then((response) => response.data);

export const getSingleUrlList = ({ customer, ecosystem, uuid }) =>
  rest
    .get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/list/${uuid}`)
    .then((response) => response.data);

export const getUrlFilteringProfiles = ({ customer, ecosystem }) =>
  rest.get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile`).then((response) => response.data);

export const getSingleUrlFilteringProfile = ({ customer, ecosystem, uuid }) =>
  rest
    .get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile/${uuid}`)
    .then((response) => response.data);

export const createUrlFilteringProfile = ({ customer, ecosystem, profile }) =>
  rest
    .post(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile`, profile)
    .then((response) => response.data);

export const updateUrlFilteringProfile = ({ customer, ecosystem, profile }) =>
  rest
    .put(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile/${profile.uuid}`, profile)
    .then((response) => response.data);

export const removeUrlFilteringProfile = ({ customer, ecosystem, profile }) =>
  rest
    .delete(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile/${profile}`)
    .then((response) => response.data);

export function fetchGroups({ customerUUID, ecosystemUUID }) {
  return rest.get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/groups`).then((response) => response.data);
}

export function fetchApplications({ customerUUID, ecosystemUUID }) {
  return rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/applications`)
    .then((response) => response.data);
}

export function fetchServices({ customerUUID, ecosystemUUID }) {
  return rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/services`)
    .then((response) => response.data);
}

export function fetchEcosystemCode({ customerUUID, ecosystemUUID }) {
  return rest.post(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/code`).then((response) => response.data);
}

export function updateEcosystemMFAData({ customerUUID, ecosystemUUID, mfaTypes }) {
  return rest
    .put(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/auth/mfa`, mfaTypes)
    .then((response) => response.data);
}
export function fetchEcosystemMFAStatus({ customerUUID, ecosystemUUID }) {
  return rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/auth/mfa`)
    .then((response) => response.data);
}

export function createGroup({ customerUUID, ecosystemUUID, name }) {
  return rest
    .post(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/groups`, {
      name,
    })
    .then((response) => response.data);
}

export function updateGroup({ customer, ecosystem, name, uuid }) {
  return rest
    .put(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/groups/${uuid}`, {
      name,
    })
    .then((response) => response.data);
}

export function deleteGroup({ customer, ecosystem, uuid }) {
  return rest
    .delete(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/groups/${uuid}`)
    .then((response) => response.data);
}

export function createObject({ object, type, ecosystem, customer }) {
  return rest
    .post(getUrlForType({ type, ecosystem, customer }), object)
    .then((response) => response.data)
    .then((data) => ({ ...data, element: type }));
}

export function updateObject({ object, type, ecosystem, customer, uuid }) {
  return rest
    .put(`${getUrlForType({ type, ecosystem, customer })}/${uuid}`, object)
    .then((response) => response.data)
    .then((data) => ({ ...data, element: type }));
}

export function deleteObject({ customer, ecosystem, object }) {
  return rest
    .delete(`${getUrlForType({ type: object.element, ecosystem, customer })}/${object.uuid}`)
    .then((response) => response.data);
}

export function updatePolicy({ entity, uuid, ecosystemUUID, customerUUID }) {
  return rest
    .put(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/policies/${uuid}`, entity)
    .then((response) => response.data);
}

export function updateDNAT({ entity, uuid, ecosystem, customer }) {
  return rest
    .put(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/nat/${uuid}`, entity)
    .then((response) => response.data);
}

export function deletePolicy({ policy, ecosystem, customer }) {
  return rest
    .delete(`/v2/customers/${customer}/ecosystems/${ecosystem}/policies/${policy}`)
    .then((response) => response.data);
}
export function deleteDNAT({ dNAT, ecosystem, customer }) {
  return rest.delete(`/v2/customers/${customer}/ecosystems/${ecosystem}/nat/${dNAT}`).then((response) => response.data);
}

export function reorderPolicy({ policy, after, ecosystem, customer }) {
  return rest
    .post(`/v2/customers/${customer}/ecosystems/${ecosystem}/policies/${policy}/reorder`, after)
    .then((response) => response.data);
}

export function createService({ customer, service, ecosystem }) {
  return rest
    .post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/services`, service)
    .then((response) => response.data);
}

export const login = (credentials) => rest.post(`v2/auth/login`, credentials).then((response) => response.data);

export const loginMFA = (credentials, code) =>
  rest.post(`v2/auth/login`, credentials, { headers: { 'X-OTP': code } }).then((response) => response.data);

export function logout() {
  return rest.post('/v2/auth/logout', {}).then((response) => response.data);
}

export const fetchReportsAggValues = ({ query, ecosystem, customer }) =>
  rest
    .post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem}/logs/_search`, query)
    .then((response) => (response.data.aggregations ? response.data.aggregations.field.buckets : []));

export async function fetchReports({ query, ecosystem, customer }) {
  const hits = await rest
    .post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem}/logs/_search`, query)
    .then((response) => response.data.hits.hits);
  return hits
    .map((report) => ({ ...report._source, id: report._id }))
    .map((report) => ({
      id: report.id,
      info: {
        date: report.EventDatetime,
        policyID: report.PolicyID,
        sessionID: report.SessionID,
      },
      service: {
        protocol: report.NetworkProtocol,
        port: report.DestinationPort || '',
      },
      application: report.Application,
      source: {
        geography: report.SourceGeography,
        ip: report.SourceIP,
        fqdn: report.SourceFQDN || '',
        natIP: report.SourceNATIP && !['0.0.0.0', '::'].includes(report.SourceNATIP) ? report.SourceNATIP : '',
        port: report.SourcePort || '',
        zone: report.SourceZone || '',
        user: report.User || '',
        isp: report.SourceISP || '',
      },
      destination: {
        geography: report.DestinationGeography,
        ip: report.DestinationIP,
        fqdn: report.DestinationFQDN || '',
        natIP:
          report.DestinationNATIP && !['0.0.0.0', '::'].includes(report.DestinationNATIP)
            ? report.DestinationNATIP
            : '',
        port: report.DestinationPort || '',
        zone: report.DestinationZone || '',
        nsp: report.NSPID || '',
      },
      action: report.EventAction,
      event: {
        eventType: report.EventType,
        eventCategory: report.EventCategory || '',
        eventSeverity: report.EventSeverity || '',
        threatCategory: report.ThreatCategory,
        threatSubCategory: report.Details || '',
        contentCategory: report.ContentCategory || '',
        contentSubCategory: report.ContentSubCategory || '',
        errCode: report.ErrCode,
      },
      status: 'active',
      stats: {
        bytesSent: report.BytesSent || 0,
        bytesTotal: report.BytesTotal || 0,
        bytesReceived: report.BytesReceived || 0,
        packetsReceived: report.PacketsReceived || 0,
        packetsSent: report.PacketsSent || 0,
        packetsTotal: report.PacketsTotal || 0,
        elapsedSeconds: report.elapsedSeconds || 0,
      },
      detail: {
        details: report.Details || '',
        gatewayID: report.GatewayID || '',
        idp: report.IdentityProviderID || '',
        device: report.DeviceID || '',
        pg: report.ProfileGroupID || '',
        allocIP: report.AllocatedAddress || '',
        allocIPv6: report.AllocatedAddress6 && report.AllocatedAddress6 !== '::' ? report.AllocatedAddress6 : '',
      },
    }));
}

export function readGateway({ customer, ecosystem, gateway }) {
  return rest
    .get(`/v2/customers/${customer}/ecosystems/${ecosystem}/gateways/${gateway}`)
    .then((response) => response.data);
}

export function regeneratePSK({ customer, ecosystem, gateway }) {
  return rest
    .put(`/v2/customers/${customer}/ecosystems/${ecosystem}/gateways/${gateway}/psk`)
    .then((response) => response.data);
}

export function getGatewayToken({ customer, ecosystem, gateway, platform }) {
  return rest
    .post(`/v2/customers/${customer}/ecosystems/${ecosystem}/gateways/${gateway}/token`, { gateway_platform: platform })
    .then((response) => response.data);
}

export function getConfigLink(token, platform) {
  return `${process.env.REACT_APP_API_URL}/v2/gateways/ipsec/config/${platform}?_token=${token}`;
}

export function acceptCommit({ customer, ecosystem }) {
  return rest
    .post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/commits`, {})
    .then((response) => response.headers);
}

export function acceptRollback({ customer, ecosystem }) {
  return rest
    .post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/rollback`, {})
    .then((response) => response.headers);
}

function getUrlForType({ type, ecosystem, customer }) {
  return `/v2/customers/${customer.uuid}/ecosystems/${ecosystem}/${Translator.urlType(type)}`;
}

export const getCustomerLogo = (customer) =>
  rest
    .get(`/v2/customers/${customer}/avatar?noerror=true`, { headers: { Accept: 'text/plain' } })
    .then((response) => response.data);

export function updateCustomerInfo({ customerUUID, customer }) {
  return rest.put(`/v2/customers/${customerUUID}`, customer).then((response) => response.data);
}

export function removeCustomer(customerUUID) {
  return rest.delete(`v2/customers/${customerUUID}`).then((response) => response.data);
}

export function fetchNSPs() {
  return rest.get(`/v2/nsps`).then((response) => response.data);
}

export function fetchRoles({ customer, ecosystem }) {
  return rest
    .get(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/roles`)
    .then((response) => response.data);
}

export function createRole({ customer, ecosystem, name }) {
  return rest
    .post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/roles`, {
      name,
    })
    .then((response) => response.data);
}

export function sendUserInvitations({ customer, ecosystem, payload }) {
  return rest
    .post(`/v2/customers/${customer}/ecosystems/${ecosystem}/invite`, {
      ...payload,
    })
    .then((response) => response.data);
}
export function enableDisableMFAForUsers({ customer, ecosystem, payload }) {
  return rest
    .put(`/v2/customers/${customer}/ecosystems/${ecosystem}/users/auth`, {
      ...payload,
    })
    .then((response) => response.data);
}
export function resetMFAForUsers({ customer, ecosystem, payload }) {
  return rest
    .put(`/v2/customers/${customer}/ecosystems/${ecosystem}/users/reset`, {
      ...payload,
    })
    .then((response) => response.data);
}

export const updateLDAPPassword = ({ customerUUID, ecosystemUUID, ldapUUID, data }) =>
  rest.put(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/idp/ldap/${ldapUUID}/credentials`, data);

export const updateLdapStatus = ({ customerUUID, ecosystemUUID, ldapUUID }) =>
  rest
    .get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/idp/ldap/${ldapUUID}/status`)
    .then((response) => response.data);

export const configOTP = (email) => rest.post(`v2/users/${email}/otp`).then((response) => response.data);

export const activate2FA = (email, code) =>
  rest.patch(`/v2/users/${email}/otp`, { code }).then((response) => response.data);
export const deactivate2FA = (email, code) =>
  rest.delete(`/v2/users/${email}/otp`, { headers: { 'X-OTP': code } }, null).then((response) => response.data);

export const createInboundCert = ({ customerUUID, ecosystemUUID, payload }) =>
  rest.post(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert`, payload).then(({ data }) => data);

export const getInboundCertList = ({ customerUUID, ecosystemUUID }) =>
  rest.get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert`).then(({ data }) => data);

export const getInboundCert = ({ customerUUID, ecosystemUUID, certUUID }) =>
  rest
    .get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert/${certUUID}`)
    .then(({ data }) => data);

export const updateInboundCert = ({ customerUUID, ecosystemUUID, certUUID, payload }) =>
  rest
    .put(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert/${certUUID}`, payload)
    .then(({ data }) => data);

export const deleteInboundCert = ({ customerUUID, ecosystemUUID, certUUID }) =>
  rest
    .delete(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert/${certUUID}`)
    .then(({ data }) => data);

export const createDecryptionProfile = ({ customerUUID, ecosystemUUID, payload }) =>
  rest
    .post(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile`, payload)
    .then(({ data }) => data);

export const getDecryptionProfileList = ({ customerUUID, ecosystemUUID }) =>
  rest.get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile`).then(({ data }) => data);

export const getDecryptionProfile = ({ customerUUID, ecosystemUUID, profileUUID }) =>
  rest
    .get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile/${profileUUID}`)
    .then(({ data }) => data);

export const updateDecryptionProfile = ({ customerUUID, ecosystemUUID, profileUUID, payload }) =>
  rest
    .put(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile/${profileUUID}`, payload)
    .then(({ data }) => data);

export const deleteDecryptionProfile = ({ customerUUID, ecosystemUUID, profileUUID }) =>
  rest
    .delete(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile/${profileUUID}`)
    .then(({ data }) => data);

export const getEcosystemReports = async ({ customerUUID, from, to, metric, aggregate, attributes }) => {
  let query = `?metric=${metric}&from=${from}&to=${to}`;
  if (aggregate) query += `&aggregate=${aggregate}`;
  if (attributes) query += `&attributes=${attributes}`;
  const response = await rest.get(`/v2/customers/${customerUUID}/dashboard${query}`).then(({ data }) => data);

  if (metric === 'network') {
    const { TimeSeries: timeSeries } = response;
    return (timeSeries || []).map((netData) => {
      const { Value: value } = netData;
      // Convert Mbps
      return {
        ...netData,
        Value: value.map((v) => (v * 8) / (1024 * 1024)),
      };
    });
  }

  return response;
};

export const getEcosystemMetrcis = async ({
  customerUUID,
  ecosystemUUID,
  from,
  to,
  metric,
  aggregate,
  attributes,
  limit,
}) => {
  const query = qs.stringify({ metric, from, to, aggregate, attributes, limit });

  return rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/dashboard?${query}`)
    .then(({ data: { TimeSeries } }) => TimeSeries);
};

export const getEcosystemThreadIndex = ({ customerUUID, from, to }) =>
  rest.get(`/v2/customers/${customerUUID}/dashboard/index?from=${from}&to=${to}`).then(({ data }) => data);

export const getEcosystemDashboardDetail = ({ customerUUID, ecosystemUUID, from, to }) =>
  rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/dashboard/detailed?from=${from}&to=${to}`)
    .then(({ data }) => data);

export const getEcosystemDashboardIndex = ({ customerUUID, ecosystemUUID, from, to }) =>
  rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/dashboard/index?from=${from}&to=${to}`)
    .then(({ data }) => data);
