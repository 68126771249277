import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Divider, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { object, string, ref } from 'yup';

import { login, loginMFA } from 'api/rest';
import WedgeModal from 'components/WedgeModal';
import Active2FA from 'Modals/Active2FA';
import MFACodeDialog from 'Modals/MFACodeDialog';
import Confirm2FA from 'Modals/Confirm2FA';
import { createErrorMessageSelector } from 'store/utils/selectors';
import { clearPasswordError, configOTP } from 'store/user/actions';
import { extractResponseErrorStatus } from 'utils/responseErrorHandler';
import { activate2FA, deactivate2FA, logout2FA, updateUserPassword } from '../senario-actions';

const validationSchema = object().shape({
  oldPassword: string().required('Please input your current password'),
  newPassword: string().min(8, 'Password cannot be less than 8 characters').required('Please input your new password'),
  confirm: string()
    .required('Please confirm your password')
    .oneOf([ref('newPassword'), null], 'Passwords must match'),
});

const AuthForm = ({
  user,
  updateUserPassword,
  configOTP,
  activate2FA,
  deactivate2FA,
  logout,
  active2FAError,
  deactive2FAError,
}) => {
  const [isOpenMfaCode, setOpenMFACode] = useState(false); // Open MFA Code dialog for update password
  const [isOpenMFA, setOpenMFA] = useState(''); // Open MFA enable/disable dialog
  const [isOpenConfirm, setOpenConfirm] = useState(''); // Open Confirm dialog after MFA enabled/disabled
  const [submitValues, setSubmitVaules] = useState(null);
  const [mfaError, setMfaError] = useState('');
  const [pwdError, setPwdError] = useState('');
  const { mfa } = user;

  const handleUpdatePassword = useCallback(
    async (values) => {
      const payload = {
        email: user.email,
        password: values.newPassword,
      };
      await updateUserPassword(payload);
    },
    [updateUserPassword, user.email],
  );

  const handleSubmitMFACode = async (code) => {
    const { oldPassword } = submitValues;
    try {
      await loginMFA({ username: user.email, password: oldPassword }, code);
      setOpenMFACode(false);
      handleUpdatePassword(submitValues);
    } catch (err) {
      const status = extractResponseErrorStatus(err);
      if (status === 424) {
        setMfaError('Your MFA code is invalid');
        return;
      }
      setPwdError('Your current password is invalid');
      setOpenMFACode(false);
    }
  };

  const handleUpdate = useCallback(
    async (values) => {
      // Reset error messages
      setPwdError('');
      setMfaError('');
      if (mfa) {
        // MFA Enabled, Check MFA Code
        setSubmitVaules(values);
        setOpenMFACode(true);
        return;
      }
      // Verify password
      try {
        await login({ username: user.email, password: values.oldPassword });
        handleUpdatePassword(values);
      } catch (err) {
        const status = extractResponseErrorStatus(err);
        let errMsg = 'Your current password is invalid';
        if (status === 424) errMsg = 'Please provide your MFA code';
        setPwdError(errMsg);
      }
    },
    [handleUpdatePassword, mfa, user.email],
  );

  const handleOpen2FA = async () => {
    if (mfa) {
      setOpenMFA('disable');
      return;
    }
    await configOTP();
    setOpenMFA('enable');
  };

  const handleActivate2FA = async (code) => {
    try {
      await activate2FA(code);
      setOpenMFA('');
      setOpenConfirm('enable');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const handleDeactivate2FA = async (code) => {
    try {
      await deactivate2FA(code);
      setOpenMFA('');
      setOpenConfirm('disable');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const handleLogout = () => {
    logout(
      isOpenConfirm === 'enable'
        ? 'Please login again with Two-Factor Authentication'
        : 'Two-Factor Authentication is disabled. Please login again.',
    );
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirm: '',
    },
    validationSchema,
    onSubmit: handleUpdate,
  });

  return (
    <>
      <Typography mt={3} fontSize={24} sx={{ color: '#76888f', fontWeight: 'bold' }}>
        Change Password
      </Typography>
      <Divider sx={{ my: 2 }} />
      <FormHelperText error sx={{ fontSize: 14 }}>
        {pwdError}
      </FormHelperText>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel>Current Password</InputLabel>
          <TextField
            fullWidth
            type="password"
            name="oldPassword"
            value={values.oldPassword}
            onChange={handleChange}
            helperText={!!touched.oldPassword && errors.oldPassword}
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            FormHelperTextProps={{ sx: { fontSize: 12, ml: 0 } }}
            data-testid="input-oldPassword"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>New Password</InputLabel>
          <TextField
            fullWidth
            type="password"
            name="newPassword"
            value={values.newPassword}
            onChange={handleChange}
            helperText={!!touched.newPassword && errors.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            FormHelperTextProps={{ sx: { fontSize: 12, ml: 0 } }}
            data-testid="input-newPassword"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>Confirm Password</InputLabel>
          <TextField
            fullWidth
            type="password"
            name="confirm"
            value={values.confirm}
            onChange={handleChange}
            helperText={!!touched.confirm && errors.confirm}
            error={Boolean(touched.confirm && errors.confirm)}
            FormHelperTextProps={{ sx: { fontSize: 12, ml: 0 } }}
            data-testid="input-confirm"
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row-reverse">
            <Button size="large" variant="contained" onClick={handleSubmit} data-testid="btn-password_update">
              Update Password
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Typography mt={3} fontSize={24} sx={{ color: '#76888f', fontWeight: 'bold' }}>
        Two-Factor Authentication (2FA)
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={20} sx={{ color: '#76888f', fontWeight: 'bold' }}>
          Two-Factor Authentication status:&nbsp;
          <Typography component="span" fontSize={16} color={mfa ? 'error' : 'primary'} sx={{ fontWeight: 'bold' }}>
            {mfa ? 'ENABLED' : 'DISABLED'}
          </Typography>
        </Typography>
        <Button
          size="large"
          variant="contained"
          color={mfa ? 'error' : 'primary'}
          sx={{ width: 120 }}
          onClick={handleOpen2FA}
          data-testid="btn-mfa_update"
        >
          {mfa ? 'Disable' : 'Enable'}
        </Button>
      </Stack>

      <WedgeModal title="Verify your MFA code" onClose={() => setOpenMFACode(false)} isOpen={isOpenMfaCode} size="tiny">
        <MFACodeDialog apiError={mfaError} onSubmit={(code) => handleSubmitMFACode(code)} />
      </WedgeModal>
      <WedgeModal
        title="Enable Two-Factor Authentication"
        onClose={() => setOpenMFA('')}
        isOpen={isOpenMFA === 'enable'}
        size="small"
      >
        <Active2FA apiError={active2FAError} onSubmit={(code) => handleActivate2FA(code)} />
      </WedgeModal>
      <WedgeModal
        title="Disable Two-Factor Authentication"
        onClose={() => setOpenMFA('')}
        isOpen={isOpenMFA === 'disable'}
        size="tiny"
      >
        <MFACodeDialog apiError={deactive2FAError} onSubmit={(code) => handleDeactivate2FA(code)} />
      </WedgeModal>
      <WedgeModal
        title={isOpenConfirm === 'enable' ? 'Enable Two-Factor Authentication' : 'Disable Two-Factor Authentication'}
        onClose={handleLogout}
        isOpen={isOpenConfirm}
        size="tiny"
      >
        <Confirm2FA action={isOpenConfirm} onClick={handleLogout} />
      </WedgeModal>
    </>
  );
};

AuthForm.propTypes = {
  user: PropTypes.object,
  updateUserPassword: PropTypes.func,
  configOTP: PropTypes.func.isRequired,
  activate2FA: PropTypes.func.isRequired,
  deactivate2FA: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  active2FAError: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
  deactive2FAError: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
};

const active2FAError = createErrorMessageSelector(['ACTIVATE_2FA']);
const deactive2FAError = createErrorMessageSelector(['DEACTIVATE_2FA']);

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  active2FAError: active2FAError(state),
  deactive2FAError: deactive2FAError(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateUserPassword: (payload) => dispatch(updateUserPassword(payload)),
  clearPasswordError: () => dispatch(clearPasswordError()),
  configOTP: () => dispatch(configOTP()),
  activate2FA: (code) => dispatch(activate2FA(code)),
  deactivate2FA: (code) => dispatch(deactivate2FA(code)),
  logout: (message) => dispatch(logout2FA(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
